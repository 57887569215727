<template>
  <div id="AddUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12 color-bg-4">
        <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
           <div class="">
            <SideMenu :indexsec="1"/>
          </div>
          <div id="stn-edituser" class="stn_edituserAdd">
            <form
              @submit.prevent="
                submit(
                  type_form,
                  team_form,
                  data.name,
                  data.num,
                  data.nationality,
                  file1
                )
              "
            >
             <div class="div_EProduct">
               <p>EDITAR JUGADOR</p>
             </div>

              <div class="row mx-0 row-one">
                <div class="EPF1_C1">
                  <label class="labelN color-1" for="inputN">Nombre</label>
                  <b-form-input
                  pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"
                    v-model="data.name"
                    required
                    id="inputN"
                    type="text"
                  />
                </div>
                <div class="">
                  <label class="labelNa color-1" for="inputNa">Nacionalidad</label>
                  <b-form-input
                  
                    v-model="data.nationality"
                    required
                    id="inputNa"
                    type="text"
                  />
                </div>
              
              </div>
               <div class="row mx-0 row-two">
                <div class="EPF2_C1">
                  <label class="labelE color-1" for="inputE">Numero</label>
                  <b-form-input   v-model="data.num" required id="inputE" type="text"  pattern="\d*" />
                </div>
                <div class="">
                    <label class="labelR color-1" for="inputR">Equipo</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{data.team_name}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.teams" :key="key">
                          <b-dropdown-item @click="changeTeam(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
                   <div class="">
                    <label class="labelPo color-1" for="inputPo">Posición</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{data.type_name}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.types" :key="key">
                          <b-dropdown-item @click="changeType(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
               
                
              </div>
                 <div class="div-image mtrow">
                  <label class="labelImg color-1" for="inputImg">Imagen</label>
                  <b-form-file
                    v-model="file1"
                    id="inputImg"
                   
                    type="file"
                    name="inputImg"
                    :placeholder="'Subir imagen'"
                  />
                </div> 
                
              <div id="edit-user-img" v-if="msgimg == 'success'  ">
               <!-- <p class="color-1">Imagen del usuario:</p> -->
           
              <img :src="this.image(data.image)"  alt="imagen de usuario">
            </div>


               <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error' && this.sec =='user'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>
              <div class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">El jugador se edito correctamente</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="editarOtro()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
          </div>

         
        </div>
          <div id="RedesSociales">

              <div v-if="data.redes != 'No se encontraron coincidencias'">
                  <div class="form_redes">

                     <div class="div_EProduct">
                        <p>EDITAR REDES SOCIALES</p>
                      </div>
                       <form
              @submit.prevent="
                updateRedes(
                 data.redes.facebook,
                 data.redes.instagram,
                 data.redes.twitter,
                 data.redes.tiktok
                )
              "
            >
            
              <div class="row mx-0 row-one">
                <div class="EPF1_C1">
                  <label class="labelFa color-1" for="inputFa">Facebook</label>
                  <b-form-input
                  
                    v-model="data.redes.facebook"
                   
                    id="inputFa"
                    type="text"
                  />
                </div>
                <div class="">
                  <label class="labelig color-1" for="inputig">Instagram</label>
                  <b-form-input
                  
                    v-model="data.redes.instagram"
                   
                    id="inputig"
                    type="text"
                  />
                </div>
              
              </div>

               <div class="row mx-0 row-one mtrow">
                <div class="EPF1_C1">
                  <label class="labeltw color-1" for="inputtw">Twitter</label>
                  <b-form-input
                  
                    v-model="data.redes.twitter"
                   
                    id="inputtw"
                    type="text"
                  />
                </div>
                <div class="">
                  <label class="labeltk color-1" for="inputtk">TikTok</label>
                  <b-form-input
                  
                    v-model="data.redes.tiktok"
                  
                    id="inputtk"
                    type="text"
                  />
                </div>
              
              </div>
            


                <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error' && this.sec =='redes'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>
                </div> 



              <div class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">Las redes se editaron correctamente</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="editarOtro()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
                   
                    </div>
              </div>
              <div v-else>

                    <div class="form_redes">
                       <div class="div_EProduct">
                        <p>AGREGAR REDES SOCIALES</p>
                      </div>
                              <form
              @submit.prevent="
                addRedes(
                facebook_form,
                instagram_form,
                twitter_form,
                tiktok_form
                )
              "
            >
            
              <div class="row mx-0 row-one">
                <div class="EPF1_C1">
                  <label class="labelFa color-1" for="inputFa">Facebook</label>
                  <b-form-input
                  
                    v-model="facebook_form"
                    
                    id="inputFa"
                    type="text"
                  />
                </div>
                <div class="">
                  <label class="labelig color-1" for="inputig">Instagram</label>
                  <b-form-input
                  
                    v-model="instagram_form"
                   
                    id="inputig"
                    type="text"
                  />
                </div>
              
              </div>

               <div class="row mx-0 row-one mtrow">
                <div class="EPF1_C1">
                  <label class="labeltw color-1" for="inputtw">Twitter</label>
                  <b-form-input
                  
                    v-model="twitter_form"
                   
                    id="inputtw"
                    type="text"
                  />
                </div>
                <div class="">
                  <label class="labeltk color-1" for="inputtk">TikTok</label>
                  <b-form-input
                  
                    v-model="tiktok_form"
                  
                    id="inputtk"
                    type="text"
                  />
                </div>
              
              </div>
            


                <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error' && this.sec =='redes'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>
                </div> 



              <div class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">Las redes se agregaron correctamente</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="editarOtro()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
                          
                    </div>

              </div>

                



         </div>
      </div>
    </div>
  </div>
</template>
<script>

import SideMenu from '../../components/SideMenu';
import Header from '../../components/Header';

import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import categorias from '../../assets/categorias.png';
import usuarios from '../../assets/usuarios.png';
import productos from '../../assets/productos.png';
import { mapActions } from "vuex";
export default {
  name: "EditPlayer",
    components: {
    SideMenu,
    Header,
  },
  data() {
    return {
      type_form:"",
      team_form:"",
      status: "",
      msg: "",
      sec:"",
      msgimg:"success",
      file1:null,
      logo: logo,
      legrafica: legrafica,
      IconSuccess: IconSuccess,
      
      categorias: categorias,
      usuarios: usuarios,
      productos: productos,

      facebook_form:'',
      twitter_form:'',
      instagram_form:'',
      tiktok_form:'',
    };
  },
  async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
  created() {
     let id = this.$route.params.id;
      let payload = {
        id: id,
        option: "players",
      };
     this.getInfoById(payload);
     this.getTypes('Posiciones')
     this.getTeams('Equipos')
  },
  computed:{
       types() {
          return this.$store.getters["type/data"];
        },
        teams() { 
          return this.$store.getters["teams/data"];
        },
        data() {
      return this.$store.getters["main/data"];
      },
      url() {
        return this.$store.getters["main/baseURL"];
      },
       user() {            
            return this.$store.getters['admin/getIdentity'];
        },
  },
  methods: {
     ...mapActions("main", ["getInfoById"]),
     ...mapActions('type', ['getTypes']),
     ...mapActions('teams', ['getTeams']),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    updateRedes: async function(facebook, instagram, twitter, tiktok){
        let id = this.$route.params.id;
        let player = this.data
          let data ={
            "id":  player.redes.id,
            "id_player":id,
            "facebook": facebook,
            "instagram": instagram,
            "tiktok": tiktok,
            "twitter":twitter
          }

         let result = await  this.$store.dispatch("main/editItem",  {option:'players/social', item:data});
            
          if(result.status=='error'){
            this.status='error'
            this.msg= result.message
            this.sec ='redes'

          }else{ // success
              this.showModal();
          }
    },
    addRedes: async function(facebook, instagram, twitter, tiktok){
        let vacias =0
        if(facebook ==''){
            vacias = vacias + 1
        }
         if(instagram ==''){
            vacias = vacias + 1
        }
         if(twitter ==''){
            vacias = vacias + 1
        }
         if(tiktok ==''){
            vacias = vacias + 1
        }

      
        if(vacias == 4){
           this.status='error'
            this.msg= 'Agrega una red social como minimo.'
            this.sec ='redes'
        }else{
            let id = this.$route.params.id;
            let data ={
              "id_player":id,
              "facebook": facebook,
              "instagram": instagram,
              "tiktok": tiktok,
              "twitter":twitter
            }

            let result = await  this.$store.dispatch("main/addItem",  {option:'players/social', item:data});
              
            if(result.status=='error'){
              this.status='error'
              this.msg= result.message
              this.sec ='redes'

            }else{ // success
                this.showModal();
            }
        }

        
       
    },
    submit: async function ( id_type, id_team, name, num, nationality,image) {
       
         let id = this.$route.params.id;
        let player = this.data

       id_type== undefined ||  id_type== ''  ||  id_type== null  ? id_type =player.id_type : id_type;
       id_team== undefined ||  id_team== ''  ||  id_team== null  ? id_team =player.id_team : id_team;
       id_type = String(id_type)
       id_team = String(id_team)
       num = String(num)

        this.status=''
        this.msg=''
             
         
       if(image !=null){
           if(image.size > 1000000){
                this.status='error'
                this.msg= 'Tamaño de imagen no válido, sube imagenes menores a 1 MB.'
               
                
            }else{
            
          var data = new  FormData();
          //Añadimos la imagen seleccionada
          data.append('id', id)
          data.append('image', image);
          data.append('name', name);
          data.append('num', num);
          data.append('id_type', id_type);
          data.append('id_team', id_team);
          data.append('nationality', nationality);
         
          data.append('_method', 'PUT');
          //Enviamos la petición
        

          
          let result = await  this.$store.dispatch("main/editItem",  {option:'players', item:data});
            
              if(result.status=='error'){
                this.status='error'
                this.msg= result.message
                this.sec ='user'
              }else{ // success
                  this.showModal();
              }
            }
          

      }else{
       
        let player ={
            'image': image,
          'name': name,
          'num': num,
          'id_type': id_type,
          'id_team': id_team,
          'nationality': nationality,
          "id":id
        }
      
      let result = await  this.$store.dispatch("main/editItem",  {option:'players', item:player});
         
          if(result.status=='error'){
            this.status='error'
            this.msg= result.message
            this.sec ='user'
          }else{ // success
              this.showModal();
          }
        
      
      }

       
         
     
     
    },
    editarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },
    showModal(){
       this.$refs['modal-editar'].show()
    },
    changeType: function (value, name) {
     
       this.type_form = value;
      this.data.type_name = name;

    },
    changeTeam: function (value, name) {
      this.team_form = value;
      this.data.team_name = name;

    },
     image: function(img){
        let src = this.url +'/get-player-image/'+img;
         this.status_img(img)
        return src
         },
    status_img:async  function (img){
    let userimg =''
      try{
          userimg = await this.$store.dispatch("main/getImage",{image: img, option:'player'})
          if(userimg.data.status=='error'){
              this.msgimg='error'
          }else{
              this.msgimg='success'
          }
      }catch(err){
          this.msgimg='error'
      }
    },
  },
};
</script>


<style scoped>

.form_redes{
    margin-left: 20vw;
    margin-bottom: 5vw;
    border-radius: 2.604166666666667VW !important;
    border: 1px solid var(--color-8) !important;
    width: 74vw;
}

</style>

